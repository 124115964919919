<template>
  <div class="demo-app">
    <div class="demo-app-top">
      <!--<button @click="toggleWeekends">toggle weekends</button>
      <button @click="gotoPast">go to a date in the past</button>
      (also, click a date/time to add an event) -->
    </div>

    <modal
      v-if="showResultsModal"
      :show.sync="showResultsModal"
      modal-classes="modal-secondary event-add-popup"
      size="lg"
      @close="resetFields"
    >
      <div class="custom-header">
        <h3 v-if="!currentId">{{ $t("EVENTS.ADD_EVENT") }}</h3>
        <h3 v-if="currentId">{{ $t("EVENTS.EDIT_EVENTS") }}</h3>

        <button @click="showResultsModal = false">
          <i class="fal fa-times"></i>
        </button>
      </div>

      <form class="add-form" @submit.prevent="onAdd">
        <div class="add-form-inner">
          <div class="form-wrapper width_1">
            <div class="form-wrapper-item">
              <base-input
                :label="`${$t('EVENTS.TITLE')} (*)`"
                :placeholder="$t('EVENTS.TITLE')"
                v-model="eventTitle"
              />
              <validation-error :errors="apiValidationErrors.name" />
            </div>

            <div class="form-wrapper-item color">
              <base-input
                :label="`${$t('EVENTS.COLOR')} (*)`"
                :placeholder="$t('EVENTS.COLOR')"
                v-model="color"
                type="color"
              />
              <validation-error :errors="apiValidationErrors.color" />
            </div>
          </div>

          <div class="form-wrapper date width_1-2">
            <base-input
              :label="`${$t('EVENTS.DATE_START')} (*)`"
              :placeholder="$t('EVENTS.DATE_START')"
              v-model="eventStart"
            >
              <flat-picker
                :placeholder="$t('EVENTS.DATE_START')"
                :config="{
                  allowInput: true,
                  enableTime: true,
                  type: 'datetime',
                  locale: $flatPickrLocale(),
                  // minDate: 'today',
                }"
                class="form-control datepicker"
                v-model="eventStart"
              >
              </flat-picker>
            </base-input>

            <validation-error :errors="apiValidationErrors.start_at" />
          </div>

          <div class="form-wrapper date width_1-2">
            <base-input
              :label="`${$t('EVENTS.DATE_END')} (*)`"
              :placeholder="$t('EVENTS.DATE_END')"
              v-model="eventEnd"
            >
              <flat-picker
                :placeholder="$t('EVENTS.DATE_END')"
                :config="{
                  allowInput: true,
                  enableTime: true,
                  type: 'datetime',
                  disabled: true,
                  minDate: eventStart,
                  locale: $flatPickrLocale(),
                  disable: [
                    function (date) {
                      return !eventStart;
                    },
                  ],
                }"
                class="form-control datepicker"
                v-model="eventEnd"
              >
              </flat-picker>
            </base-input>

            <validation-error :errors="apiValidationErrors.end_at" />
          </div>

          <div v-if="!filterAuctionId" class="form-wrapper width_1">
            <base-input
              v-model="selectedAuctionId"
              :label="`${$t('COMMON.AUCTION')}`"
              :placeholder="$t('COMMON.AUCTION')"
            >
              <auction-selector
                :auction="`${selectedAuctionId}`"
                :placeholder="$t('COMMON.AUCTION')"
                :filterable="true"
                :filterAuctionType="AUCTION_TYPE_EXPRESS"
                :showAll="false"
                @auctionChanged="
                  (auctionId) => {
                    selectedAuctionId = auctionId;
                  }
                "
              />
            </base-input>

            <validation-error :errors="apiValidationErrors.auction_id" />
          </div>

          <div class="form-wrapper width_1 recurent">
            <!-- <base-checkbox
              :label="`${$t('EVENTS.RECURENT')}`"
              :placeholder="$t('EVENTS.RECURENT')"
              v-model="isRecurrent"
            >
              <span>{{ $t("EVENTS.RECURENT") }}</span>
            </base-checkbox> -->

            <label>{{ $t("EVENTS.RECURANT_AUCTION") }}</label>

            <el-radio-group v-model="isRecurrent">
              <el-radio :label="false">{{ $t("COMMON.NO") }}</el-radio>
              <el-radio :label="true">{{ $t("COMMON.YES") }}</el-radio>
            </el-radio-group>

            <validation-error :errors="apiValidationErrors.recurrent" />

            <div v-if="isRecurrent" class="form-wrapper-item">
              <base-input
                :label="`${$t('EVENTS.FREQUENCE')} (*)`"
                :placeholder="$t('EVENTS.FREQUENCE')"
              >
                <el-select
                  v-model="eventRepeted"
                  clearable
                  class="select-primary pagination-select"
                  :placeholder="$t('AUCTIONS.FREQUENCE')"
                >
                  <el-option value="" :label="$t('EVENTS.SELECT')" />
                  <el-option value="day" :label="$t('EVENTS.ALL_DAYS')" />
                  <el-option value="week" :label="$t('EVENTS.ALL_WEEKS')" />
                  <el-option value="month" :label="$t('EVENTS.ALL_MONTH')" />
                </el-select>
              </base-input>

              <validation-error :errors="apiValidationErrors.frequency" />
            </div>

            <div v-if="isRecurrent" class="form-wrapper-item date">
              <base-input
                :label="`${$t('EVENTS.OCCURRENCE_END_DATE')} (*)`"
                :placeholder="$t('EVENTS.OCCURRENCE_END_DATE')"
                v-model="recurentEventEnd"
              >
                <flat-picker
                  :placeholder="$t('EVENTS.OCCURRENCE_END_DATE')"
                  :config="{
                    allowInput: true,
                    enableTime: true,
                    type: 'datetime',
                    disabled: true,
                    minDate: eventEnd,
                    locale: $flatPickrLocale(),
                    disable: [
                      function (date) {
                        return !eventEnd;
                      },
                    ],
                  }"
                  class="form-control datepicker"
                  v-model="recurentEventEnd"
                >
                </flat-picker>
              </base-input>

              <validation-error :errors="apiValidationErrors.recurent_end_at" />
            </div>
          </div>
        </div>

        <div class="submit-wrapper">
          <base-button
            type="button"
            class="btn btn-sm elite-submit"
            native-type="submit"
            :disabled="loading"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading" />
            {{ !currentId ? $t("EVENTS.ADD") : $t("EVENTS.EDIT") }}
          </base-button>
        </div>
      </form>
    </modal>

    <modal
      v-if="showVModal"
      name="show-modal"
      :show.sync="showVModal"
      modal-classes="modal-secondary event-view-popup"
      size="lg"
      @close="resetFields"
    >
      <div class="custom-header">
        <h3>{{ $t("EVENTS.VIEW_EVENT") }}</h3>
        <button @click="showVModal = false">
          <i class="fal fa-times"></i>
        </button>
      </div>

      <div class="event-view">
        <div class="event-frame"></div>
        <dl class="event-view-item full">
          <dt>
            {{ $t("EVENTS.TITLE") }}
          </dt>
          <dd>
            {{ eventTitle }}
          </dd>
        </dl>

        <dl class="event-view-item">
          <dt>
            {{ $t("EVENTS.DATE_START") }}
          </dt>
          <dd>
            {{ $formatDate(eventStart) }}
          </dd>
        </dl>

        <dl class="event-view-item">
          <dt>{{ $t("EVENTS.DATE_END") }}</dt>
          <dd>
            {{ $formatDate(eventEnd) }}
          </dd>
        </dl>

        <dl class="event-view-item full">
          <dt>
            {{ $t("COMMON.AUCTION") }}
          </dt>
          <dd>
            {{ selectedAuction.name }}
          </dd>
        </dl>

        <dl class="event-view-item">
          <dt>{{ $t("COMMON.VEHICLES") }}</dt>
          <dd>
            <base-button
              class="elite-button reorganize"
              icon
              size="sm"
              @click="openReorderModal"
            >
              <span class="btn-inner--text">
                {{ $t("EVENTS.REORGANIZE_VEHICLES") }}
              </span>
            </base-button>
          </dd>
        </dl>
      </div>

      <div class="event-view-footer" v-if="canEdit">
        <base-button
          v-if="canEdit && isRecurrent"
          class="elite-button delete-all"
          icon
          size="sm"
          @click="deleteAllEvents"
          style="margin-left: 16px"
        >
          <span class="btn-inner--text">
            {{ $t("EVENTS.DELETE_ALL_EVENT") }}
          </span>
        </base-button>

        <base-button
          v-if="canEdit"
          class="elite-button delete"
          icon
          size="sm"
          @click="deleteEvent"
          style="margin-left: 16px"
        >
          <span class="btn-inner--text">
            {{ $t("EVENTS.DELETE_THIS_EVENT") }}
          </span>
        </base-button>

        <base-button
          v-if="canEdit"
          class="elite-button edit"
          icon
          size="sm"
          @click="editEventModal"
        >
          <span class="btn-inner--text">
            {{ $t("COMMON.EDIT") }}
          </span>
        </base-button>
      </div>
    </modal>

    <FullCalendar
      class="demo-app-calendar"
      ref="fullCalendar"
      defaultView="dayGridMonth"
      :header="{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
      }"
      :plugins="calendarPlugins"
      :weekends="calendarWeekends"
      :events="this.events"
      :locale="$i18n.locale"
      @dateClick="dateClick"
      :eventsSet="handleEvents"
      @eventClick="showModal"
    />

    <div
      v-if="isReorganizeModalOpened"
      class="resizable-wrapper"
      v-bind:class="[isReorganizeModalOpened ? 'show' : 'hide']"
      :key="renderKey * 200"
    >
      <div
        class="resizable-wrapper-content"
        v-resizable-modal="'Event_REORGANIZE_VEHICLES'"
      >
        <div class="resizable-wrapper-content-header">
          <div class="resizable-wrapper-content-header-left">
            <h1>
              {{ $t("EVENTS.REORGANIZE_VEHICLES") }}
            </h1>
          </div>

          <div></div>

          <div class="resizable-wrapper-content-header-right">
            <button class="expand" id="expand-button">
              <img
                src="../../../../../public/img/e-arrows-expand.svg"
                alt="icon"
              />
            </button>

            <button class="close" @click="() => closeReorderModal()">
              <i class="fal fa-times"></i>
            </button>
          </div>
        </div>

        <div class="resizable-wrapper-content-body">
          <ReorderVehiclesComponent
            v-if="openEvent"
            :eventId="openEvent.id"
            :canEdit="isEventInProgress"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import swal from "sweetalert2";
import { ref } from "vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import AuctionSelector from "@/components/AuctionSelector.vue";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Option, Select, RadioGroup, Radio } from "element-ui";
import { Tabs, TabPane } from "@/components";
import moment from "moment";
import ReorderVehiclesComponent from "./components/ReorderVehiclesComponent.vue";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import { QUERY_ACTIONS_REORGANIZE_VEHICES } from "@/constants/events";
import { AUCTION_TYPE_EXPRESS } from "@/constants/auctions";

const picked = ref("one");

export default {
  components: {
    FullCalendar,
    flatPicker,
    AuctionSelector,
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    Tabs,
    TabPane,
    ReorderVehiclesComponent,
  },

  props: {
    filterAuctionId: {
      type: String,
      default: null,
      description: "Auction id",
    },
  },

  mixins: [formMixin, requestErrorMixin],

  data() {
    const eventId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewEventModalOpened = false;
    let isEditEventModalOpened = false;
    let isAddEventModalOpened = false;
    let isReorganizeModalOpened = false;
    let openEvent = null;
    if (eventId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewEventModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditEventModalOpened = true;
      } else if (action === QUERY_ACTIONS_REORGANIZE_VEHICES) {
        isReorganizeModalOpened = true;
      }
      openEvent = { id: eventId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddEventModalOpened = true;
    }

    return {
      calendarPlugins: [
        // plugins must be defined in the JS
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin, // needed for dateClick
      ],
      calendarWeekends: true,
      selectable: true,
      events: [],
      selectedDate: [],

      total: 0,
      pagination: {
        perPage: 500,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      loading: true,
      query: null,
      selectedRows: [],
      eventTitle: "",
      eventStart: "",
      eventEnd: "",
      eventRepeted: "none",
      showResultsModal: false,
      showVModal: false,
      calendar: "",
      currentId: eventId ?? null,
      excludedDates: new Date("2024/07/09"),
      picked: "one",
      color: this.getRandomColor(),
      isRecurrent: 0,
      recurentEventStart: "",
      recurentEventEnd: "",
      parentId: null,
      isEdit: false,
      isDisabled: false,
      // auctions: [],
      selectedAuction: null,
      selectedAuctionId: this.filterAuctionId ?? "",
      isViewEventModalOpened,
      isEditEventModalOpened,
      isAddEventModalOpened,
      isReorganizeModalOpened,
      openEvent,
      renderKey: 1,
      AUCTION_TYPE_EXPRESS,
    };
  },

  computed: {
    isEventInProgress() {
      const now = moment.utc().tz("America/Toronto").format("YYYY-MM-DD HH:mm");

      console.log("now", now);
      console.log("eventStart", this.eventStart);
      console.log("eventEnd", this.eventEnd);

      if (
        (this.eventStart && moment(now).isBefore(moment(this.eventStart))) ||
        (this.eventStart &&
          this.eventEnd &&
          moment(now).isBetween(moment(this.eventStart), moment(this.eventEnd)))
      ) {
        return true;
      }
      return false;
    },

    canEdit() {
      const now = moment.utc().tz("America/Toronto").format("YYYY-MM-DD HH:mm");

      if (
        this.eventStart &&
        moment(now).tz("America/Toronto").isBefore(moment(this.eventStart))
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    async deleteAllEvents(Event) {
      // console.log(Event);
      const confirmation = await swal.fire({
        text: this.$t("EVENTS.DELETE_ALL_EVENT"),
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn base-button btn-info",
        cancelButtonClass: "btn base-button",
      });

      if (!confirmation.value) {
        return;
      }

      try {
        await this.$store.dispatch("events/deleteAllByParent", this.currentId);

        this.showVModal = false;

        this.$notify({
          type: "success",
          message: this.$t("EVENTS.EVENT_DELETED"),
        });

        this.events = this.events.filter(
          (event) => event.id !== this.currentId
        );

        this.getList();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteEvent(Event) {
      // console.log(Event);
      const confirmation = await swal.fire({
        text: this.$t("EVENTS.DELETE_THIS_EVENT"),
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn base-button btn-info",
        cancelButtonClass: "btn base-button",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("events/destroy", this.currentId);
          this.showVModal = false;

          this.$notify({
            type: "success",
            message: this.$t("EVENTS.EVENT_DELETED"),
          });
          this.events = this.events.filter(
            (event) => event.id !== this.currentId
          );
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async onAdd(arg) {
      let eventData = {
        name: this.eventTitle,
        start_at: this.$convertDateToUtc(this.eventStart),
        end_at: this.$convertDateToUtc(this.eventEnd),
        recurrent: this.isRecurrent,
        frequency: this.eventRepeted,
        color: this.color,
        recurent_start_at: this.recurentEventStart
          ? this.$convertDateToUtc(this.recurentEventStart)
          : this.recurentEventStart,
        recurent_end_at: this.recurentEventEnd
          ? this.$convertDateToUtc(this.recurentEventEnd)
          : this.recurentEventEnd,
        type: "events",
        auction_id: this.selectedAuctionId,
      };

      this.setApiValidation([]);

      // if (!this.eventTitle || !this.eventStart || !this.eventEnd) {
      //   this.$notify({
      //     type: "danger",
      //     message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
      //   });

      //   return;
      // }

      // if (!(this.eventStart < this.eventEnd)) {
      //   this.$notify({
      //     type: "danger",
      //     message: this.$t("EVENTS.DATE_INVALIDE"),
      //   });

      //   return;
      // }

      // if (this.eventRepeted !== "none" && !this.recurentEventEnd) {
      //   this.$notify({
      //     type: "danger",
      //     message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
      //   });
      //   this.showResultsModal = false;
      //   return;
      // }

      if (this.currentId) {
        const confirmation = await swal.fire({
          text: this.$t("EVENTS.EDIT_EVENTS"),
          customClass: {
            popup: "delete-popup",
          },
          buttonsStyling: false,
          showCancelButton: true,
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonText: this.$t("COMMON.YES"),
          confirmButtonClass: "btn base-button btn-info",
          cancelButtonClass: "btn base-button",
        });

        if (this.picked === "one") {
          try {
            await this.$store.dispatch("events/update", {
              ...eventData,
              id: this.currentId,
            });

            this.$notify({
              type: "success",
              message: this.$t("EVENTS.EVENTS_UPDATED"),
            });

            this.showResultsModal = false;

            this.getList();

            const obj = JSON.parse(event.target.response);

            this.$emit("onViewEvent", event, true);

            this.loading = false;
          } catch (error) {
            // console.log(error);
            this.$notify({
              type: "danger",
              message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
            });

            this.setApiValidation(error.response.data.errors);
          }

          return;
        }

        try {
          if (
            confirmation.value === true &&
            this.isRecurrent &&
            this.picked === "all"
          ) {
            await this.$store.dispatch(
              "events/deleteAllByParent",
              this.currentId
            );
          }
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
        }
      }

      if (
        this.picked !== "one" ||
        (this.currentId === null && this.picked === "one")
      ) {
        try {
          await this.$store.dispatch("events/add", eventData);
          this.$notify({
            type: "success",
            message: this.$t("EVENTS.EVENTS_ADDED"),
          });

          this.resetFields();

          this.getList();

          const obj = JSON.parse(event.target.response);

          this.$emit("onViewEvent", event, true);

          this.loading = false;

          this.showResultsModal = false;
        } catch (error) {
          // console.log(error);
          // this.$notify({
          //   type: "danger",
          //   message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          // });

          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
          this.setApiValidation(error.response.data.errors);
          this.loading = false;
        }
      }

      // this.showResultsModal = false;
    },

    showModal(arg) {
      this.eventTitle = arg.event._def.title;
      this.eventStart = moment(arg.event.start).format("YYYY-MM-DD HH:mm");

      this.eventEnd = arg.event.end
        ? moment(arg.event.end).format("YYYY-MM-DD HH:mm")
        : moment(arg.event._instance.range.end).format("YYYY-MM-DD HH:mm");

      this.currentId = arg.event._def.publicId;
      this.openEvent = { id: this.currentId };

      this.recurentEventStart = arg.event._def.extendedProps.sartRec
        ? moment(arg.event._def.extendedProps.sartRec).format(
            "YYYY-MM-DD HH:mm"
          )
        : arg.event._def.extendedProps.sartRec;
      this.recurentEventEnd = arg.event._def.extendedProps.endRecu
        ? moment(arg.event._def.extendedProps.endRecu).format(
            "YYYY-MM-DD HH:mm"
          )
        : arg.event._def.extendedProps.endRecu;

      this.isRecurrent = arg.event._def.extendedProps.isRecu;
      this.eventRepeted = arg.event._def.extendedProps.freq;
      this.color = arg.event.backgroundColor;
      this.selectedAuctionId = arg.event._def.extendedProps.auction_id;
      this.selectedAuction = arg.event._def.extendedProps.auction;

      this.showVModal = true;
    },

    toggleWeekends() {
      this.calendarWeekends = !this.calendarWeekends;
    },

    gotoPast() {
      let calendarApi = this.$refs.fullCalendar.getApi(); // from the ref="..."
      calendarApi.gotoDate("2000-01-01"); // call a method on the Calendar object
    },

    addEvent(event) {},

    getDaysOfWeek(startDate, endDate) {
      const daysOfWeek = [];
      const currentDate = new Date(startDate);

      while (currentDate < new Date(endDate)) {
        // console.log("ssss");

        const day = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
        if (!daysOfWeek.includes(day)) {
          daysOfWeek.push(day);
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      // Sort the days for better readability (optional)
      daysOfWeek.sort((a, b) => a - b);

      return daysOfWeek;
    },

    async editEventModal() {
      // no need to call confirmation if the event is not recurrent
      if (!this.isRecurrent) {
        this.showResultsModal = true;
        this.showVModal = false;
        return;
      }

      const htmlContent = `
        <div class="event-type" ${
          this.isRecurrent && this.currentId ? "" : 'style="display:none;"'
        }>
          <div class="event-type-item">
            <input v-model="picked" type="radio" id="one" value="one" name="picked" ${
              this.picked === "one" ? "checked" : ""
            }>
            <label for="one">${this.$t("EVENTS.THIS_EVENTS")}</label>
          </div>
          <div class="event-type-item">
            <input v-model="picked" type="radio" id="all" value="all" name="picked" ${
              this.picked === "all" ? "checked" : ""
            }>
            <label for="all">${this.$t("EVENTS.ALL_EVENTS")}</label>
          </div>
        </div>
      `;

      const confirmation = await swal.fire({
        text: this.$t("EVENTS.EDIT_EVENTS"),
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn base-button btn-info",
        cancelButtonClass: "btn base-button",
        html: htmlContent,
        onOpen: () => {
          const oneRadio = document.getElementById("one");
          const allRadio = document.getElementById("all");
          oneRadio.addEventListener("change", () => {
            this.picked = "one";
            console.log(this.picked);
          });
          allRadio.addEventListener("change", () => {
            this.picked = "all";
            console.log(this.picked);
          });
        },
      });

      if (confirmation.isConfirmed) {
        console.log("Picked:", this.picked);
      } else {
        return;
      }

      if (this.currentId) {
        this.isDisabled = true;
        // Update event
        const updatedEvent = {
          title: this.eventTitle,
          start: this.eventStart,
          end: this.eventEnd,
          recurrent: this.eventRepeted,
        };
      }
      this.showResultsModal = true;
      this.showVModal = false;
    },

    async dateClick(selectInfo) {
      this.isDisabled = false;

      let dateToday = new Date();

      if (
        new Date(selectInfo.dateStr) <
        dateToday.setDate(dateToday.getDate() - 1)
      ) {
        const confirmation = await swal.fire({
          text: this.$t("EVENTS.NOT_SELECTED"),
          customClass: {
            popup: "delete-popup",
          },
          showCancelButton: false,
          confirmButtonClass: "btn base-button",
        });

        return;
      }

      this.calendar = selectInfo.view.context.calendar;
      let nextDate = new Date(selectInfo.dateStr);
      const formattedDate = nextDate.toISOString().slice(0, 16);

      this.eventStart = formattedDate;
      this.eventEnd = formattedDate;
      this.showResultsModal = true;
      console.log("ev", formattedDate);

      this.currentId = null;
      this.eventRepeted = "none";
      this.eventTitle = "";
      this.isRecurrent = false;
    },

    handleEvents(events) {
      this.events = events;
    },

    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;

        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          include: "auction",
          filter: {
            ...(this.query ? { name: this.query } : {}),
            ...(this.filterAuctionId
              ? { auction_id: this.filterAuctionId }
              : {}),
          },
        };

        await this.$store.dispatch("events/list", params);

        this.events = this.$store.getters["events/list"];
        // this.total = this.$store.getters["events/listTotal"];

        this.events = this.events
          .filter((event) => event.parent_id || event.recurrent == 0)
          .map((event) => {
            this.picked = "one";
            this.currentId = null;
            this.isRecurrent = false;

            const format = "YYYY-MM-DD HH:mm";

            return {
              title: event.name,

              // start: event.start_at,
              // end: event.end_at,
              start: this.$timeZoneDateFormat(event.start_at, format),
              end: this.$timeZoneDateFormat(event.end_at, format),

              color: event.color,
              id: event.id,

              // sartRec: event.recurent_start_at,
              // endRecu: event.recurent_end_at,
              sartRec: event.recurent_start_at
                ? this.$timeZoneDateFormat(event.recurent_start_at, format)
                : "",
              endRecu: event.recurent_end_at
                ? this.$timeZoneDateFormat(event.recurent_end_at, format)
                : "",

              isRecu: event.recurrent,
              freq: event.frequency,
              auction_id: event.auction_id,
              auction: event.auction,
            };
          });

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    resetFields() {
      console.log("reset field called");

      this.currentId = null;
      this.eventTitle = "";
      this.eventStart = "";
      this.eventEnd = "";
      this.isRecurrent = false;
      this.recurentEventEnd = "";
      this.eventRepeted = "none";
      this.color = this.getRandomColor();
      this.selectedAuctionId = "";
    },

    getRandomColor() {
      var letters = "0123456789ABCDEF";

      var color = "#";

      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }

      return color;
    },

    openReorderModal() {
      this.showVModal = false;
      this.isReorganizeModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Events",
          query: { id: this.currentId, action: "reorganize-vehicles" },
        }).href
      );
    },

    closeReorderModal() {
      this.isAddEventModalOpened = false;
      this.isViewEventModalOpened = false;
      this.isEditEventModalOpened = false;
      this.isReorganizeModalOpened = false;
      this.openEvent = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Events",
          query: {},
        }).href
      );
    },
  },

  async mounted() {
    this.getList();
    // this.fetchAuctions();
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterReseller: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterAuctionId: {
      handler: "getListDebounced",
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
// you must include each plugins' css
// paths prefixed with ~ signify node_modules
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";

.modal-secondary .modal-content {
  background-color: #dce2e5;
  color: #212529;
}
</style>
