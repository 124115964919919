<template>
  <div class="container-fluid">
    <!-- <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
    </span> -->

    <!-- <div v-if="!loading" class="elite-tabs-wrapper"> -->
    <div class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <img src="./../../../../../../public/img/e-global.svg" alt="icon" />
            {{ $t("COMMON.GLOBAL") }}
          </span>

          <ReorderVehiclesForm
            :loading="loading"
            :eventId="eventId"
            :canEdit="canEdit"
            :labels="labels"
            :vehicles="vehicles"
            :formErrors="formErrors"
            @queryChanged="(changes) => (query = changes)"
            @isValidatedChanged="(changes) => (isValidated = changes)"
            @brandChanged="(changes) => (brand = changes)"
            @modelChanged="(changes) => (model = changes)"
            @formSubmitted="handleSubmit"
            @formChanged="() => (alertLeave = true)"
            @refresh="get"
          />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { Tabs, TabPane } from "@/components";
import alertLeave from "@/mixins/alert-leave-mixin";
import ReorderVehiclesForm from "../partials/ReorderVehiclesForm.vue";
import { EXPRESS_ENTRY_COLUMNS } from "@/constants/auctions";

export default {
  layout: "DashboardLayout",

  components: {
    Tabs,
    TabPane,
    ReorderVehiclesForm,
  },

  mixins: [alertLeave],

  props: {
    eventId: {
      type: [String, Number],
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      labels: [],
      vehicles: {},
      loading: false,
      formErrors: null,
      loading: false,
      query: null,
      isValidated: null,
      brand: null,
      model: null,
    };
  },

  created() {
    this.get();
  },

  watch: {
    query: {
      handler: "getListDebounced",
    },
    isValidated: {
      handler: "getListDebounced",
    },
    brand: {
      handler: "getListDebounced",
    },
    model: {
      handler: "getListDebounced",
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.get();
    }, 300),
    async get() {
      this.loading = true;

      this.vehicles = {};

      try {
        await this.$store.dispatch("labels/list", {
          filter: { category: "auction_express_column_name" },
          sort: "name",
        });
        this.labels = this.$store.getters["labels/list"];

        const params = {
          filter: {
            event_id: this.eventId,
            ...(this.query ? { search: this.query } : {}),
            ...(this.isValidated ? { is_validated: this.isValidated } : {}),
            ...(this.brand ? { make: this.brand } : {}),
            ...(this.model ? { model: this.model } : {}),
          },
          include: "organization,expressAuctionEntryColumnLabel",
          sort: "express_auction_entry_column_label_id,express_auction_entry_number",
        };

        await this.$store.dispatch("vehicles/list", params);
        const list = this.$store.getters["vehicles/list"];

        const grouped = Object.groupBy(
          list,
          (v) => v.expressAuctionEntryColumnLabel.name
        );

        this.labels.forEach((column) => {
          if (!grouped[column.name]) {
            grouped[column.name] = [{ id: 0, is_empty_card: true }];
          }
        });

        const sortedObj = {};
        const sortedKeys = Object.keys(grouped).sort();

        sortedKeys.forEach((key) => {
          sortedObj[key] = grouped[key];
        });

        this.vehicles = { ...sortedObj };

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        this.loading = false;
      }
    },
    async handleSubmit(reorderedVehicles) {
      this.loading = true;

      try {
        const map = Object.entries(reorderedVehicles).reduce(
          (acc, currentList) => {
            return [
              ...acc,
              ...currentList[1].map((vehicle) => {
                return {
                  id: vehicle.id,
                  express_auction_entry_column_label_id:
                    vehicle.expressAuctionEntryColumnLabel.id,
                  express_auction_entry_number:
                    vehicle.express_auction_entry_number,
                  is_validated: vehicle.validated
                    ? 1
                    : vehicle.is_validated
                    ? 1
                    : 0,
                };
              }),
            ];
          },
          []
        );

        await this.$store.dispatch("events/reorganizeVehicles", {
          eventId: this.eventId,
          vehicles: map,
        });

        this.get();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        throw error;
      }

      this.loading = false;
    },
  },
};
</script>
