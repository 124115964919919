<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-8">
          <base-button
            class="elite-button add"
            icon
            size="sm"
            :disabled="loading || !canEdit || !hasOneChecked"
            @click="uncheckAllUnvalidatedVehicles"
          >
            <span class="btn-inner--text">
              {{ $t("EVENTS.CANCEL_SELECTIONS") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            :disabled="loading || !canEdit"
            @click="checkAllUnvalidatedVehicles"
          >
            <span class="btn-inner--text">
              {{ $t("EVENTS.SELECT_ALL") }}
            </span>
          </base-button>
        </div>

        <div class="col text-right">
          <base-button
            class="elite-button add"
            icon
            size="sm"
            :disabled="loading || !canEdit"
            @click="() => (showVehiclesSelectorModal = true)"
          >
            <span class="btn-inner--text">
              {{ $t("VEHICLES.ADD_VEHICLE") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="title-header reorder-filter">
      <div class="row">
        <div class="col-6">
          <base-input
            v-model="query"
            type="search"
            prepend-icon="far fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
            class="search"
          />
        </div>

        <div class="col-6">
          <el-select
            class="select-primary pagination-select"
            v-model="isValidated"
            :placeholder="$t('COMMON.STATUS')"
          >
            <el-option
              class="select-primary"
              :label="$t('COMMON.ALL')"
              :value="null"
            />

            <el-option
              class="select-primary"
              :label="$t('COMMON.YES')"
              :value="1"
            />

            <el-option
              class="select-primary"
              :label="$t('COMMON.NO')"
              :value="0"
            />
          </el-select>

          <make-selector
            :filterable="true"
            :placeholder="$t('VEHICLES.MAKE')"
            :value="brand"
            @makeChanged="(changes) => (brand = changes)"
          />

          <model-selector
            :filterable="true"
            :make="brand"
            :value="model"
            @modelChanged="
              (changes) => {
                model = changes;
              }
            "
          />
        </div>
      </div>
    </div>

    <div class="all-infos">
      <form
        class="add-form reorganizer"
        ref="profile_form"
        @submit.prevent="handleSubmit"
        @keydown.enter.prevent="() => {}"
      >
        <div class="reorganizer-inner">
          <div
            v-for="(list, group) in listGroup"
            :key="group"
            :class="`reorganizer-inner-item`"
          >
            <div class="reorganizer-inner-item-title" :class="group">
              <span>{{ group }}</span>
            </div>

            <div class="reorganizer-inner-item-content">
              <draggable
                :value="list"
                group="vehicles"
                draggable=">.can-be-dragged"
                @start="onDragStart"
                @end="onDragEnd"
                @change="(e) => handleReorder(e, group)"
              >
                <div
                  v-for="item in list"
                  :key="item.id"
                  class="item-to-drag"
                  :class="{
                    'can-be-dragged':
                      !item.is_empty_card &&
                      item.auction_status ==
                        VEHICLE_AUCTION_STATUS_SOON.toLowerCase(),
                  }"
                >
                  <div v-if="item.is_empty_card" class="empty">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_10600_132130)">
                        <mask
                          id="mask0_10600_132130"
                          style="mask-type: luminance"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="40"
                          height="40"
                        >
                          <path d="M0 0H40V40H0V0Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_10600_132130)">
                          <path
                            d="M19.9987 3.3335C10.7987 3.3335 3.33203 10.8002 3.33203 20.0002C3.33203 29.2002 10.7987 36.6668 19.9987 36.6668C29.1987 36.6668 36.6654 29.2002 36.6654 20.0002C36.6654 10.8002 29.1987 3.3335 19.9987 3.3335ZM19.9987 33.3335C12.632 33.3335 6.66536 27.3668 6.66536 20.0002C6.66536 16.9168 7.71536 14.0835 9.48203 11.8335L28.1654 30.5168C25.8373 32.3472 22.9602 33.3395 19.9987 33.3335ZM30.5154 28.1668L11.832 9.4835C14.1601 7.65311 17.0372 6.66081 19.9987 6.66683C27.3654 6.66683 33.332 12.6335 33.332 20.0002C33.332 23.0835 32.282 25.9168 30.5154 28.1668Z"
                            fill="#9CA3AF"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_10600_132130">
                          <rect width="40" height="40" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>{{ $t("EVENTS.NO_VEHICLES_ADDED") }}</span>
                  </div>

                  <div v-else>
                    <div class="item">
                      <div class="item-header">
                        <div class="item-header-left">
                          <base-checkbox
                            v-model="item.validated"
                            :disabled="loading || !canEdit || item.is_validated"
                          />
                          <span>
                            {{
                              `${item.expressAuctionEntryColumnLabel.name} ${item.express_auction_entry_number}`
                            }}
                          </span>
                        </div>

                        <div class="item-header-right">
                          <badge
                            :type="item.is_validated ? 'success' : 'yellow'"
                          >
                            {{
                              item.is_validated
                                ? $t(
                                    `VEHICLES.AUCTION_STATUS_${item.auction_status.toUpperCase()}`
                                  )
                                : $t(`VEHICLES.TO_CONFIRMED`)
                            }}
                          </badge>
                        </div>
                      </div>
                      <div class="item-content">
                        <div class="item-content-image">
                          <img
                            :src="item.exterior_photos[0]"
                            style="
                              width: 80px;
                              height: 52px;
                              border-radius: 8px;
                            "
                          />
                        </div>
                        <div class="item-content-text">
                          <p class="model">
                            {{
                              `${item.make.toUpperCase()} ${item.model.toUpperCase()}`
                            }}
                          </p>
                          <h3>
                            {{ `${item.series?.toUpperCase() ?? ""}` }}
                          </h3>
                          <p class="style">
                            {{ `${item.style?.toUpperCase() ?? ""}` }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="item-footer"
                        style="
                          display: flex;
                          justify-content: space-between;
                          gap: 12px;
                        "
                      >
                        <ul>
                          <li>
                            {{ item.odometer_mileage }}
                            {{ item.odometer_type.toUpperCase() }}
                          </li>
                          <li>
                            {{ item.pickup_location?.formatted_address ?? "" }}
                          </li>
                          <li>{{ item.organization.name }}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </div>

        <div class="submit-wrapper">
          <base-button
            type="button"
            class="btn btn-sm elite-submit"
            native-type="submit"
            :disabled="loading || !canEdit"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading" />
            {{ $t("COMMON.SAVE") }}
          </base-button>
        </div>
      </form>
    </div>

    <div
      v-if="showVehiclesSelectorModal"
      class="resizable-wrapper"
      v-bind:class="[showVehiclesSelectorModal ? 'show' : 'hide']"
    >
      <div class="resizable-wrapper-content" v-resizable-modal="'VEHICLES'">
        <div class="resizable-wrapper-content-header">
          <h1>
            {{ $t("VEHICLES.ADD_VEHICLE") }}
          </h1>
          <button
            class="close"
            @click="() => (showVehiclesSelectorModal = false)"
          >
            <i class="fal fa-times"></i>
          </button>
        </div>

        <div class="resizable-wrapper-content-body">
          <VehiclesSelector
            toggle-multi-select
            :eventId="eventId"
            @refresh="refreshList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Option, Select } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import MakeSelector from "@/components/MakeSelector.vue";
import ModelSelector from "@/components/ModelSelector.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { EXPRESS_ENTRY_COLUMNS } from "@/constants/auctions";
import draggable from "vuedraggable";
import {
  VEHICLE_STATUS_COMPLETED,
  VEHICLE_AUCTION_STATUS_SOON,
  VEHICLE_AUCTION_STATUS_COMPLETED,
} from "@/constants/vehicles";
import VehiclesSelector from "./VehiclesSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    flatPicker,
    BaseInput,
    MakeSelector,
    ModelSelector,
    ValidationError,
    HtmlEditor,
    [Select.name]: Select,
    [Option.name]: Option,
    draggable,
    VehiclesSelector,
  },

  mixins: [formMixin],

  props: ["eventId", "labels", "vehicles", "canEdit", "formErrors", "loading"],

  data() {
    return {
      EXPRESS_ENTRY_COLUMNS,
      listGroup: {},
      dragging: false,
      query: null,
      isValidated: null,
      brand: null,
      model: null,
      VEHICLE_STATUS_COMPLETED,
      VEHICLE_AUCTION_STATUS_SOON,
      VEHICLE_AUCTION_STATUS_COMPLETED,
      showVehiclesSelectorModal: false,
    };
  },

  computed: {
    total() {
      return Object.keys(this.listGroup).length;
    },
    hasOneChecked() {
      return Object.keys(this.listGroup).some((key) => {
        return this.listGroup[key].some((vehicle) => {
          return vehicle.validated;
        });
      });
    },
  },

  created() {},

  methods: {
    checkAllUnvalidatedVehicles() {
      const groups = { ...this.listGroup };

      Object.keys(groups).forEach((key) => {
        groups[key].forEach((vehicle) => {
          if (!vehicle.is_validated) {
            vehicle.validated = true;
          }
        });
      });

      this.listGroup = { ...groups };
    },
    uncheckAllUnvalidatedVehicles() {
      const groups = { ...this.listGroup };

      Object.keys(groups).forEach((key) => {
        groups[key].forEach((vehicle) => {
          if (!vehicle.is_validated) {
            vehicle.validated = false;
          }
        });
      });

      this.listGroup = { ...groups };
    },
    onDragStart() {
      this.dragging = true;
    },
    onDragEnd() {
      this.dragging = false;
    },
    handleReorder(event, group) {
      console.log("handleReorder", event);

      const label = this.labels.find((label) => label.name === group);

      console.log("found label id", label);

      const groups = { ...this.listGroup };

      if (event.added) {
        groups[group].push({
          ...event.added.element,
          expressAuctionEntryColumnLabel: label,
          // express_auction_entry_column_label_id: labelId,
        });
      }

      if (event.moved) {
        const currentItem = groups[group][event.moved.newIndex];

        const movedItem = groups[group][event.moved.oldIndex];

        groups[group][event.moved.newIndex] = {
          ...movedItem,
          expressAuctionEntryColumnLabel: label,
          // express_auction_entry_column_label_id: labelId,
        };

        groups[group][event.moved.oldIndex] = {
          ...currentItem,
          expressAuctionEntryColumnLabel: label,
          // express_auction_entry_column_label_id: labelId,
        };
      }

      if (event.removed) {
        groups[group].splice(event.removed.oldIndex, 1);
      }

      Object.keys(groups).forEach((key) => {
        if (groups[key].length === 0) {
          groups[key] = [{ id: 0, is_empty_card: true }];
        } else {
          groups[key] = groups[key].filter((item) => !item.is_empty_card);
        }
      });

      // increment express_auction_entry_number
      groups[group] = groups[group].map((item, index) => {
        console.log("increment index", item, index);
        return {
          ...item,
          express_auction_entry_number: index + 1,
        };
      });

      this.listGroup = { ...groups };
    },
    async handleSubmit() {
      const groups = { ...this.listGroup };

      Object.keys(groups).forEach((key) => {
        groups[key] = groups[key].filter(
          (vehicle) => vehicle.id !== 0 || !vehicle.is_empty_card
        );
      });

      this.$emit("formSubmitted", groups);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    refreshList() {
      this.showVehiclesSelectorModal = false;

      this.$emit("refresh");
    },
  },

  watch: {
    vehicles(vehicles) {
      this.listGroup = vehicles;
    },
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    query(query) {
      this.$emit("queryChanged", query);
    },
    isValidated(isValidated) {
      this.$emit("isValidatedChanged", isValidated);
    },
    brand(brand) {
      this.$emit("brandChanged", brand);
    },
    model(model) {
      this.$emit("modelChanged", model);
    },
  },
};
</script>

<style lang="css" scoped>
.custom-input .form-group {
  margin-bottom: 0px;
}
</style>
