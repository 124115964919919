<template>
  <div class="elite-tabs-wrapper-content">
    <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
    </span>

    <VehicleListTable
      toggle-multi-select
      :filterStatus="VEHICLE_STATUS_COMPLETED"
      :filterAuctionType="AUCTION_TYPE_EXPRESS"
      :filterIsValidated="0"
      @rowsSelectedChanged="rowsSelectedChanged"
    />

    <div>
      <base-button
        class="elite-button add"
        icon
        size="sm"
        :disabled="loading || !selectedRows.length"
        @click="submit"
      >
        <span class="btn-inner--text">
          {{ $t("COMMON.VALIDATE") }}
        </span>
      </base-button>
    </div>
  </div>
</template>

<script>
import {
  VEHICLE_STATUS_COMPLETED,
  VEHICLE_AUCTION_STATUS_SOON,
} from "@/constants/vehicles";
import VehicleListTable from "../../VehicleManagement/partials/VehicleListTable.vue";
import { AUCTION_TYPE_EXPRESS } from "@/constants/auctions";

export default {
  layout: "DashboardLayout",

  components: {
    VehicleListTable,
  },

  props: ["eventId"],

  data() {
    return {
      VEHICLE_STATUS_COMPLETED,
      VEHICLE_AUCTION_STATUS_SOON,
      AUCTION_TYPE_EXPRESS,
      selectedRows: [],
      loading: false,
    };
  },

  computed: {},

  created() {},

  methods: {
    rowsSelectedChanged(rows) {
      this.selectedRows = rows;
    },
    async submit() {
      this.loading = true;

      try {
        await this.$store.dispatch("events/assignVehicles", {
          eventId: this.eventId,
          vehicles: this.selectedRows.map((v) => ({
            id: v.id,
            event_id: this.eventId,
          })),
        });

        this.$emit("refresh");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }

      this.loading = false;
    },
  },

  watch: {},
};
</script>
